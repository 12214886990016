



























import { Component, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

const ID_PLAN_PREMIUM_ANNUAL = process.env.VUE_APP_PLAN_PREMIUM_TO_FREE;

@Component({
  components: {
    Modal,
  },
})
export default class ModalPlanUpdate extends Vue {
  get title() {
    return this.$store.getters.modalPlanUpdate.title;
  }

  get description() {
    return this.$store.getters.modalPlanUpdate.description;
  }

  handleRedirectPlan() {
    this.$router.push({
      name: 'Payment',
      params: {
        feature: 'planos',
        productId: ID_PLAN_PREMIUM_ANNUAL,
      },
    });
  }
}
