export const MAINTENANCE_REF: Maintenance = {
  production: 'site',
  development: 'site-staging',
  staging: 'site-staging',
  test: 'site-staging',
};

export interface Maintenance {
  production: string;
  development: string;
  staging: string;
  test: string;
}
