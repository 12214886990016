















































import { Component, Mixins } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

import CreditsConsumer from '@/mixins/CreditsConsumer';

const ID_PLAN_PREMIUM_ANNUAL = process.env.VUE_APP_PLAN_PREMIUM_TO_FREE;

@Component({
  components: {
    Modal,
  },
})
export default class ModalConsumerCredits extends Mixins(CreditsConsumer) {
  handleRedirectPlan() {
    this.$router.push({
      name: 'Payment',
      params: {
        feature: 'planos',
        productId: ID_PLAN_PREMIUM_ANNUAL,
      },
    });
  }
}
